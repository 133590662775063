<template>
  <div class="configure">
    <div class="mains">
      <MySide></MySide>
      <router-view></router-view>
    </div>
    <MyNav ref="MyNav"></MyNav>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    MySide: () => import("@/components/MySide.vue"),
  },
  data() {
    return {
      setTime1: null,
    };
  },
  beforeDestroy() {
    if (this.setTime1) {
      clearTimeout(this.setTime1);
    }
    this.setTime1 = null;
  },
  methods: {
    ...mapMutations("user", ["SET_NAV"]),
  },
};
</script>

<style lang="less" scoped>
.configure {
  width: 100%;
  min-height: 100vh;
  padding-top: 62px;
  background-color: #071a2c;
  padding: 62px 48px 30px;

  .mains {
    width: 100%;
    min-height: 975px;
    display: flex;
    margin-top: 12px;
    padding: 3px 0;
  }
}
</style>
